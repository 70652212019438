import { XIcon } from '@heroicons/react/solid'
import toast, { Toaster, ToastBar } from 'react-hot-toast'

export default function Notifications() {
  return (
    <div>
      <Toaster
        position='top-right'
        toastOptions={{
          style: {
            color: 'black',
            backgroundColor: 'white',
            border: '1px solid #9ca3af'
          },
          loading: { theme: { primary: 'purple', secondary: 'black' } } as any
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <div
                className='flex'
                onMouseDown={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  e.nativeEvent.stopImmediatePropagation()
                }}
              >
                {icon}
                <div className='text-sm font-normal'>{message}</div>
                {t.type !== 'loading' && (
                  <div className='flex my-1 ml-4 shrink-0'>
                    <button
                      className='inline-flex text-gray-400 bg-transparent rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                      onClick={() => {
                        toast.dismiss(t.id)
                      }}
                    >
                      <span className='sr-only'>Close</span>
                      <XIcon className='w-5 h-5' aria-hidden='true' />
                    </button>
                  </div>
                )}
              </div>
            )}
          </ToastBar>
        )}
      </Toaster>
    </div>
  )
}
