'use client'
import Notifications from "@/ui/components/notifications"

interface AuthLayoutProps {
  children: React.ReactNode
}

export default function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <div className="min-h-screen">
      {children}
      <Notifications />
    </div>
  )
}